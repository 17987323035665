





























































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util } from "@/common/Views/comm/util";
import { BasicsClass } from "../../../common/BasicsClass";
import {
    BigData,
    FrameData,
    Temperature,
    SlaughterStatic,
    SmartInventory,
    SelectPopInt,
    Frame,
    VideoList,
} from "@/common/Views/bigData/bigData";

import { MilkProduction } from "@/Interface/BreedingModule/BigScreen";

import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {
    getBigScreenCompanyVideoGroupCode,
    getBigScreennewInsuranceVideoList,
    getBigScreenCompanyVideoGroupCodeV333,
    getBigScreennewInsuranceVideoListV333,
    judgeExistWarninLightAuth,
    getBigScreenGovernmentysVideoGroupTree,
    getBigScreenGovernmentysVideoList,
} from "@/api/index";

@Component({
    components: {
        ExistingNumber: () =>
            import("@/views/largeScreen/firm/modules/existingNumber.vue"),
        DeviceCase: () =>
            import("@/views/largeScreen/firm/modules/deviceCase.vue"),
        HealthWarning: () =>
            import("@/views/largeScreen/firm/modules/healthWarning.vue"),
        Echarts8: () =>
            import("@/views/NewbigScreenenterprise/components/echartsjg.vue"),
        Top: () => import("@/views/largeScreen/bankScreen/module/NewTop.vue"),
        Echarts6: () =>
            import("@/views/NewbigScreenenterprise/components/echarts3.vue"),
        Death: () =>
            import("@/views/NewbigScreenenterprise/components/Death.vue"),
        Echartssell: () =>
            import("@/views/NewbigScreenenterprise/components/echartssell.vue"),
        Map: () =>
            import("@/views/largeScreen/bankScreen/module/bigScreenMap.vue"),
        Milk: () =>
            import("@/views/NewbigScreenenterprise/components/Milk.vue"),
        // Map: () => import("@/views/largeScreen/bankScreen/module/map.vue"),
    },
})
export default class HelloWorld extends Vue {
    private currentDate: string = "";
    private currentTime: string = "";
    private showModule: boolean = false;

    private refreshModule: string = "";
    private moduleNumber: number = 0;

    private popHeight = 100;
    private popTitle = "";
    private popMessage = "";
    private popHeightStop = 10;
    private popTime: any = "";
    private videoSource = "firm";
    private isYsvideo: boolean = true;
    private popList = [];

    private refreshOrder = [
        "ExistingNumber",
        "Echarts6",
        "Death",
        "Echartssell",
        "Echarts8",
        "HealthWarning",
    ];

    private showImg: boolean = false;
    private handleClosrImg() {
        // 关闭图片
        this.showImg = false;
    }

    private time: any = "";

    private showCitySelection: boolean = false;
    private changeCity: string = "-1";
    private changeName: string = "";
    private goback(): void {
        new BasicsClass().BasicGet(
            "/animal/web/menu/currentMenus",
            {},
            false,
            true,
            (res: any) => {
                // let url = res.data[0].children[0].url;
                // const path = this.$route.path === url ? '/homeep' : url;
                // console.log('----router: ', this.$route.path, url, path)
                this.$router.push("/Welcome");
            }
        );
    }
    private form = {
        id: "",
        farmId: "",
        currentType: "",
        pid: "",
    };

    private transmitForm = {
        id: "",
        farmId: "",
        currentType: "",
        pid: "",
        deviceStatus: "",
    };

    private groupCodeList = [];
    private typeList: any[] = [];
    private langType = "";
    private created(): void {
        // this.setDate()
        this.langType = this.$i18n.locale;
        this.warningTime = Util.getStartCurrentTime();
        let setDate = () => {
            let current = new Date();
            this.currentDate = Util.dateUtil(current);
            this.currentTime = current.toTimeString().split(" ")[0];
            window.setTimeout(setDate, 1000);
        };
        setDate();
        this.getGroupCode();
    }

    private ShowVc: boolean = true;
    private enterpriseScreenTitle = "";
    private async mounted() {
        this.enterpriseScreenTitle =
            localStorage.getItem("enterpriseScreenTitle") || "";
        await this.getJudgeExistWarninLightAuth();
        await this.getTypeSort();
        await this.getPopData();

        let that = this;
        await document.addEventListener("visibilitychange", function() {
            if (document.visibilityState == "hidden") {
                that.ShowVc = false;
            } else {
                that.ShowVc = true;
                that.getGroupCode();
                that.getTypeSort();
                that.getPopData();
            }
        });
    }

    private beforeDestroy(): void {
        // console.log('--------销毁定时器', this.time);
        window.clearInterval(this.time);
        window.clearInterval(this.nextTime);
        window.clearInterval(this.getVoiceTime);
        this.time = "";
        this.getVoiceTime = null;
        this.nextTime = null;
    }
    private getVoiceTime: any = null;
    private getWarningVoiceOnTime() {
        const that = this;
        if (that.getVoiceTime !== null) {
            clearInterval(that.getVoiceTime);
        }
        that.getVoiceTime = setInterval(() => {
            if (!that.playAudioType && that.showWarningFlag) {
                that.getWarningVoice();
            }
        }, 3600000);
    }
    private setPopMessage(): void {
        // if(this.popList.length == 0){
        //   this.popTitle = ''
        //   this.popMessage = ''
        //   this.popHeight = 100
        //   return
        // }
        if (this.popHeight >= 90) {
            this.popHeight = 10;
        } else {
            this.popHeight += 10;
        }
        this.popTitle = this.popList[0].typeCode;
        this.popMessage = this.popList[0].message;
        this.popList.splice(0, 1);
    }

    private popPlay() {
        this.popTime = window.setInterval(() => {
            if (this.popList.length == 0) {
                this.popTitle = "";
                this.popMessage = "";
                this.popHeightStop = this.popHeight;
                this.popHeight = 100;
                window.clearInterval(this.popTime);
                this.popTime = "";
            } else {
                this.setPopMessage();
            }
        }, 5000);
    }
    private nextTime: any = null;
    private nextVideoList() {
        const that = this;
        if (that.nextTime !== null) {
            clearInterval(that.nextTime);
        }
        that.nextTime = setInterval(() => {
            if (!that.ChildshowDingVideo) {
                if (that.ChildShowVideoListFlag) {
                    if (
                        that.PageObj.pageSize * that.PageObj.pageNo >
                        that.PageObj.totalCount
                    ) {
                        that.PageObj.pageNo = 0;
                    }
                    if (that.PageObj.totalCount > 4) {
                        that.getNextPage();
                    }
                } else {
                    if (
                        that.page.pageSize * that.page.pageNo >
                        that.page.totalCount
                    ) {
                        that.page.pageNo = 0;
                    }
                    if (that.PageObj.totalCount > 4) {
                        that.next();
                    }
                }
            }
        }, 30000);
    }

    private timeRefresh(): void {
        // console.log(this.warningTime, "this.warningTime");
        clearInterval(this.time);
        this.time = setInterval(() => {
            // 这边是定时器，用来刷新数据的，但是好像除了第五个的 rightSideMessage 被调用，其他的都没调用
            // console.log('timeRefresh 的 setInterval 被调用了', this.refreshOrder);
            this.refreshModule = this.refreshOrder[this.moduleNumber];
            if (this.moduleNumber == 4) {
                this.getPopData();
            }
            if (this.moduleNumber == 5) {
                this.moduleNumber = 0;
            } else {
                this.moduleNumber += 1;
            }
        }, 10000);
    }
    private warningTime = "";
    private warningList = [];
    private playAudioType = false;
    private getWarningVoice() {
        return new Promise((resolve, reject) => {
            let params = {
                groupCode: this.transmitForm.id,
                farmId: this.transmitForm.farmId,
                time: this.warningTime,
                animalTypeId: this.transmitForm.currentType
                    ? this.transmitForm.currentType
                    : 2 || this.form.currentType
                    ? this.transmitForm.currentType
                    : 2,
            };
            if (params.groupCode === "") {
                if (this.form.id !== "") {
                    params.groupCode = this.form.id;
                } else {
                    params.groupCode = "0";
                }
            }
            new BasicsClass().BasicPost(
                "/animal/web/bigScreenForCompanyNew/warningVoiceBroadcast",
                params,
                false,
                false,
                true,
                (res: any) => {
                    // console.log(res.data);
                    this.warningTime = Util.getCurrentTime();
                    Object.entries(res.data).forEach(([key, value]) => {
                        if (value) {
                            this.warningList.push(key);
                        }
                    });
                    if (this.warningList.length > 0) {
                        this.playAudioType = true;
                        this.playAudioSequence(0);
                    } else {
                        this.playAudioType = false;
                    }
                    resolve(" ");
                }
            );
        });
    }
    private audio: HTMLAudioElement | null = null;
    private warningTextList = {
        fallOff: "耳标出现异常脱落",
        damage: "耳标出现异常损坏",
        deviceOffline: "设备出现异常离线",
        applyOutColumn: "牛只出栏需要审核",
        leave: "牛只发生非法离栏",
        deliver: "待产母牛近期分娩",
        estrus: "系统预测母牛发情",
    };

    private playAudioSequence(index: number) {
        if (index >= this.warningList.length) {
            this.playAudioType = false;
            return;
        }

        const item = this.warningList[index];
        const audioUrl = `https://marktrace-cattle.oss-cn-hangzhou.aliyuncs.com/animal-web/audio/${item}.mp3`;
        const warningText = this.warningTextList[item];
        this.$message({
            type: "warning",
            message: warningText,
        });
        this.audio = new Audio(audioUrl);
        this.audio.play();
        this.audio.addEventListener("ended", () => {
            // console.log("Audio playback ended for item: " + item);
            this.playAudioSequence(index + 1);
        });
    }
    private getPopData() {
        window.clearInterval(this.popTime);
        this.popTime = "";
        this.popList.splice(0, this.popList.length);
        return new Promise((resolve, reject) => {
            let params = {
                groupCode: this.transmitForm.id,
                farmId: this.transmitForm.farmId,
                animalTypeId:
                    this.transmitForm.currentType || this.form.currentType,
            };
            if (params.groupCode === "") {
                if (this.form.id !== "") {
                    params.groupCode = this.form.id;
                } else {
                    params.groupCode = "0";
                }
            }
            new BasicsClass().BasicGet(
                "/animal/web/bigScreenForCompanyNew/rightSideMessage",
                params,
                false,
                true,
                (res: any) => {
                    if (this.popList.length > 0) {
                        this.popList.push(...res.data);
                        // this.popPlay()
                    } else {
                        this.popList.push(...res.data);
                        this.popTitle = this.popList[0].typeCode;
                        this.popMessage = this.popList[0].message;
                        this.popList.splice(0, 1);
                        this.popHeight = this.popHeightStop;
                        this.popPlay();
                    }
                    resolve(" ");
                }
            );
        });
    }

    private authorityAdcode: number = null; //当前账户的地图权限
    private authorityName: string = null; // 当前账户权限对应的名称
    private curentAdcode: number = 0; // 当前区域的 adcode

    private getGroupCode() {
        // console.log(123)
        return new Promise((resolve, reject) => {
            new BasicsClass().BasicGet(
                "/animal/web/bigScreenForCompanyNew/companyGroupCode",
                {},
                false,
                true,
                (res: any) => {
                    // console.log(res)
                    this.groupCodeList = res.data;
                    this.changeCity = res.data[0].id;
                    this.authorityAdcode = res.data[0].id;
                    this.authorityName = res.data[0].name;
                    this.changeName = res.data[0].name;
                    this.form.id = res.data[0].id;

                    let that = this;
                    that.handleCity({
                        id: res.data[0].id,
                        gdGroupCode: res.data[0].id,
                        name: res.data[0].name,
                        longitude: res.data[0].longitude,
                        latitude: res.data[0].latitude,
                        farmId: "",
                        pid: "",
                    });

                    resolve(" ");
                }
            );
        });
    }

    private getTypeSort() {
        return new Promise((resolve, reject) => {
            new BasicsClass().BasicGet(
                "/animal/web/bigScreenForCompanyNew/pigCattleAndSheepSelection",
                {
                    groupCode: this.form.id,
                    farmId: this.form.farmId,
                },
                false,
                true,
                (res: any) => {
                    this.typeList.splice(0, this.typeList.length);
                    // 首次获取
                    this.form.currentType = res.data[0];
                    this.getBigScreenGovernmentVideoGroupCodeData(res.data[0]);
                    this.getBigScreenGovernmentysVideoGroupTree(res.data[0]);
                    // console.log(res)
                    for (let i in res.data) {
                        if (res.data[i] == 1) {
                            this.typeList.push({
                                img: require("@/assets/pig.png"),
                                checkImg: require("@/assets/pigs.png"),
                                id: 1,
                            });
                        } else if (res.data[i] == 2) {
                            this.typeList.push({
                                img: require("@/assets/cow.png"),
                                checkImg: require("@/assets/cows.png"),
                                id: 2,
                            });
                        } else if (res.data[i] == 3) {
                            this.typeList.push({
                                img: require("@/assets/sheep.png"),
                                checkImg: require("@/assets/sheeps.png"),
                                id: 3,
                            });
                        } else if (res.data[i] == 4) {
                            this.typeList.push({
                                img: require("@/assets/luotuo.png"),
                                checkImg: require("@/assets/luotuoAct.png"),
                                id: 4,
                            });
                        }
                        // if(this.typeList.length == 3){
                        // 	// console.log(this.typeList)
                        // 	if(!this.showModule){
                        // 		this.showModule = true
                        // 	}
                        // 	resolve(' ')
                        // }
                        if (<any>i == res.data.length - 1) {
                            if (!this.showModule) {
                                this.showModule = true;
                                this.timeRefresh();
                            }
                            resolve(" ");
                        }
                    }
                }
            );
        });
    }

    private onMarker(val: any) {
        this.showImg = true;
    }

    private handleMap(item: any) {
        debugger;
        this.form.currentType = "";
        this.typeList.splice(0, this.typeList.length);
        this.form.id = item.id;
        this.form.farmId = "";
        this.form.pid = "";
        this.changeCity = item.id;
        this.changeName = item.name;
        this.getTypeSort();
    }

    // 子组件更新地区名称
    private setCurentAreaName(name: string): void {
        // this.changeName = name;
        this.changeName = name;
    }

    // 子组件更新地区adcode
    private reCount: number = 0;
    private setCurentAdcode(adcode: number) {
        // console.log('地图组件更新了当前区域，所以我需要设置当前区域的数据', adcode);
        this.curentAdcode = adcode;
        // console.log(JSON.parse(JSON.stringify(this.form)));
        this.transmitForm.id = this.curentAdcode.toString();
        this.form.id = this.curentAdcode.toString();
        // this.form = JSON.parse(JSON.stringify(this.form));
        // console.log(JSON.parse(JSON.stringify(this.transmitForm)));
        this.reCount += 1;
        if (this.reCount > 1) {
            this.getTypeSort();
        }
    }

    setType(id) {
        // console.log('setType: id', id);
        this.form.currentType = id;
        if (id === 4) {
            //如果等与骆驼 就请求对应的骆驼数据
            this.getMilkYieldData();
        }
        this.getBigScreenGovernmentVideoGroupCodeData(id);
        this.getBigScreenGovernmentysVideoGroupTree(id);
        // this.refreshMap(this.form.id);
        this.$refs.dpMap.initData(this.form.id, id);
    }

    private refreshMap(item) {
        // console.log('refreshMap ----: ', gdGroupCode)

        // 高德新地图同步
        // 根据点击地区节点的ID 去匹配高德地区对应的ID，展开地图
        let id: any = 0;

        if (item.farmId) {
            id = item.pid;
            this.$refs.dpMap.isOutFarm = true;
            this.$refs.dpMap.outFarmName = item.name;

            // console.log('表明这是一个农场，同时我想知道它所属的机构', item, this.curentAdcode);

            if (item.pid === this.curentAdcode) {
                // console.log('表明这是同一个区域下面的农场切换，地图不会变，但是需要变更sysfram接口')
                // this.$refs.dpMap.initData(this.form.pid, this.form.currentType);
                // this.$refs.dpMap.outerTextMarker({ name: item.name, longitude: item.longitude, latitude: item.latitude});
                // console.log('逻辑变更，变成不用树结构的农场信息打点，直接用sysfram返回的点位信息打点，这样，要丢失，一起丢失')
                this.$refs.dpMap.initData(this.form.pid, this.form.currentType);
                return;
            }
        } else {
            id = item.gdGroupCode;

            if (
                this.curentAdcode.toString() === item.id.toString() &&
                item.id.toString().length === 6
            ) {
                // console.log(this.curentAdcode, item.id, item.gdGroupCode)
                // console.log('表明现在是由农场返回到它的父亲一级，因为地图不会变，所以点位也就没更新，需要自己手动设置');

                this.$refs.dpMap.initData(id, this.form.currentType);
                this.$refs.dpMap.outerTextMarker({
                    name: item.name,
                    longitude: item.longitude,
                    latitude: item.latitude,
                });
                return;
            }
        }

        // 根据点击地区节点的ID 去匹配高德地区对应的ID，展开地图
        if (id && id.toString().length === 6) {
            // console.log('表明我点击的是最后一层级，六位地区码');
            // 这个code地图是获取不到下一级区域的，所以无法获取地图节点对象，需要我们自己设置名称和点位信息
            this.$refs.dpMap.setLastLevelObj(
                item.name,
                item.longitude,
                item.latitude
            );
        }

        for (let i = Number(id).toString().length; i < 6; i += 1) {
            id += "0";
        }
        if (id === "000000") id = 100000;
        // console.log('补足id为六位，末尾加0：', id);
        // 点击了树结构，需要同步地图变化
        // this.$refs.dpMap.switch2AreaNode(Number(id), null, id);
        let that = this;

        if (this.isInit) {
            setTimeout(() => {
                this.isInit = false;
                self();
                // console.log('-----初始化地图数据');
            }, 3000);
        } else {
            self();
        }

        function self() {
            // console.log(Boolean(that), 666);
            if (that && that.$refs && that.$refs.dpMap) {
                // console.log('我成功的调用了地图组件', id);
                that.$refs.dpMap.switch2AreaNode(Number(id), null, id);
            } else {
                setTimeout(() => {
                    // console.log('地图还没有加载成功，所以我要一直调用');
                    self();
                }, 100);
            }
        }
    }

    private isInit: boolean = true;

    //给每个有详情的组件使用的groupcode id
    private sendGroupCodeId: string = "";
    private sendFarmId: string = "";
    private sendGroupName: string = "";
    private sendFarmName: string = "";

    //处理直辖市id的函数
    private changeGroupCodeFun(item: any) {
        this.sendGroupCodeId = "";
        this.sendFarmId = "";
        this.sendGroupName = "";
        this.sendFarmName = "";
        if (item.farmId == null) {
            this.sendGroupName = item.name;
            if (item.id == "1101") {
                this.sendGroupCodeId = "11";
            } else if (item.id == "1201") {
                this.sendGroupCodeId = "12";
            } else if (item.id == "3101") {
                this.sendGroupCodeId = "31";
            } else if (item.id == "5001" || item.id == "5002") {
                this.sendGroupCodeId = "50";
            } else {
                this.sendGroupCodeId = item.id;
            }
        } else {
            this.sendGroupCodeId = "";
            this.sendGroupName = "";
            this.sendFarmName = item.name;
            if (item.name == "中国") this.sendFarmName = "";
            this.sendFarmId = item.farmId;
        }
    }

    private async handleCity(item: any): void {
        this.changeGroupCodeFun(item);
        // console.log('-------点击的树状图', item, JSON.parse(JSON.stringify(this.form)));
        this.form.id = item.id;
        this.form.farmId = item.farmId;
        this.form.pid = item.pid;
        this.form.currentType = "";
        // 千万不要在这里面修改 transmitForm 的属性，因为非常多的子组件watch了这个对象，这边修改了，会造成子组件监听变化，调用后台接口
        // 而参数属性还没有赋值完全，会报错，而且还会重复调用两次，这行代码不删，注释引起警戒
        // this.transmitForm.farmId = item.farmId;
        // console.log('-------from赋值完毕', JSON.parse(JSON.stringify(this.form)));

        this.typeList.splice(0, this.typeList.length);
        this.changeCity = item.id;
        this.changeName = item.name;
        this.showCitySelection = false;
        await this.getTypeSort();
        this.refreshMap(item);
    }

    @Watch("form.currentType", { deep: true })
    private getDate(newData, oldData) {
        // console.log(newData)
        // console.log(oldData)
        if (newData != "") {
            // console.log('watch到了 form.currentType 的变化', JSON.parse(JSON.stringify(this.form)))
            this.transmitForm.id = this.form.id;
            this.transmitForm.farmId = this.form.farmId;
            this.transmitForm.currentType = this.form.currentType;
            this.transmitForm.pid = this.form.pid;
            window.clearInterval(this.time);
            if (this.showWarningFlag) {
                window.clearInterval(this.nextTime);
                this.nextTime = null;
                this.nextVideoList();
            }
            this.time = "";
            this.moduleNumber = 0;
            this.timeRefresh();
            this.getPopData();
            this.chooseList();
        }
    }

    //产奶量接口请求
    private getMilkYieldData() {
        // let form: MilkProduction = {
        //     groupCode: this.form.id,
        //     dateType: this.form.currentType,
        // };
        // new BreedingModule().getMilkYield(form, (data: any) => {});
    }

    private videoList: VideoList[] = [];

    private chooseBigData: BigData = new BigData();

    private TopFlag: boolean = false;
    private page: any = {
        pageSize: 4,
        pageNo: 1,
        totalCount: 0,
    };

    private AddListNumber: number = 4;

    private chooseList() {
        this.TopFlag = false;
        return new Promise((resolve, reject) => {
            this.chooseBigData.chooseListTwo(
                this.transmitForm,
                this.page,
                (data: any) => {
                    this.page.totalCount = data.page.totalCount;
                    let listArr: any[] = [];
                    if (data.data.length != 0) {
                        for (
                            let i = 0;
                            i < data.data.length;
                            i += this.AddListNumber
                        ) {
                            listArr.push(
                                data.data.slice(i, i + this.AddListNumber)
                            );
                        }
                    } else {
                        listArr = [];
                    }
                    //处理数组
                    this.videoList = listArr;
                    // console.log("firm1021", this.videoList);
                    this.TopFlag = true;
                    resolve(" ");
                },
                (errData: any) => {
                    this.videoList = [];
                }
            );
        });
    }

    private Return: boolean = true;
    private PrevReturn: boolean = true;
    private nextList() {
        this.chooseBigData.chooseListTwo(
            this.transmitForm,
            this.page,
            (data: any) => {
                let listArr: any[] = [];
                if (data.data.length != 0) {
                    for (
                        let i = 0;
                        i < data.data.length;
                        i += this.AddListNumber
                    ) {
                        listArr.push(
                            data.data.slice(i, i + this.AddListNumber)
                        );
                    }
                } else {
                    listArr = [];
                }
                //处理数组
                this.videoList = listArr;
                this.TopFlag = true;
            },
            (errData: any) => {
                this.videoList = [];
            }
        );
    }

    private prevList() {
        this.chooseBigData.chooseListTwo(
            this.transmitForm,
            this.page,
            (data: any) => {
                let listArr: any[] = [];
                if (data.data.length != 0) {
                    for (
                        let i = 0;
                        i < data.data.length;
                        i += this.AddListNumber
                    ) {
                        listArr.push(
                            data.data.slice(i, i + this.AddListNumber)
                        );
                    }
                } else {
                    listArr = [];
                }
                //处理数组
                this.videoList = listArr;
                this.TopFlag = true;
            },
            (errData: any) => {
                this.videoList = [];
            }
        );
    }

    prev() {
        this.page.pageNo--;
        if (this.page.pageNo >= 1) {
            this.prevList();
        } else {
            this.page.pageNo = 1;
        }
    }

    next() {
        if (this.page.pageSize * this.page.pageNo < this.page.totalCount) {
            this.page.pageNo++;
            this.nextList();
        }
    }
    private VideoGroupCodeData: any[] = []; //传递的左侧地址数据
    private VideoListData: any = {
        page: {
            totalCount: 0,
        },
    }; //传递的视频列表数据
    private PageObj: any = {
        pageNo: 1,
        pageSize: 4,
    };
    private generateRandomLevelId() {
        // 生成一个随机的唯一标识符，可以根据需要修改长度
        const uniqueId = Math.random()
            .toString(36)
            .substring(2, 10);

        return uniqueId;
    }

    private generateLevelId(data: any) {
        data.forEach((item: any) => {
            // 生成随机的 levelId
            item.levelId = this.generateRandomLevelId();

            // 如果当前项有子项，则递归调用此函数
            if (item.children && item.children.length > 0) {
                this.generateLevelId(item.children);
            }
        });
    }
    private ChildObjVal: any = {}; //子组件传递过来的值
    private VideolaunchData: any[] = []; //传递的左侧地址数据

    private getBigScreenGovernmentVideoGroupCodeData(
        animalTypeId: number | string
    ) {
        getBigScreenCompanyVideoGroupCodeV333(animalTypeId, (data: any) => {
            this.VideoGroupCodeData = data.data;
            this.generateLevelId(this.VideoGroupCodeData);
            console.log(
                this.VideoGroupCodeData,
                "VideoGroupCodeData856465499999"
            );
            this.VideolaunchData = this.extractLevelIds();
        });
    }
    private ysVideoGroupTreeData: any[] = [];
    private getBigScreenGovernmentysVideoGroupTree(
        animalTypeId: number | string
    ) {
        getBigScreenGovernmentysVideoGroupTree(animalTypeId, (data: any) => {
            console.log(data.data, "1335");
            this.ysVideoGroupTreeData = [data.data];
            console.log(
                this.ysVideoGroupTreeData,
                "ysVideoGroupTreeData6465432199999"
            );
            this.generateLevelId(this.ysVideoGroupTreeData);
        });
    }
    private extractLevelIds() {
        const levelIds: any = [];

        // 遍历第一层数据
        this.VideoGroupCodeData.forEach((item: any) => {
            // 添加当前项的levelId到数组中
            levelIds.push(item.levelId);
        });
        return levelIds;
    }
    private SetFatherVideoSendData(val: any) {
        //子子组件的emit事件  告诉此组件需要请求数据
        this.ChildObjVal = val;
        this.ChildObjVal.animalTypeId = this.form.currentType;
        this.PageObj.pageNo = 1; //地址变化之后  将PageObj的 pageNo = 1
        this.getBigScreenGovernmentVideoListData(
            this.ChildObjVal,
            this.PageObj
        ); //请求数据  并传递给子组件
    }
    private SetYSVideoSendData(val: any) {
        //子子组件的emit事件  告诉此组件需要请求数据
        this.ChildObjVal = val;
        this.ChildObjVal.animalTypeId = this.form.currentType;
        this.PageObj.pageNo = 1; //地址变化之后  将PageObj的 pageNo = 1
        this.getBigScreenGovernmentysVideoListData(
            this.ChildObjVal,
            this.PageObj
        ); //请求数据  并传递给子组件
    }
    private getBigScreenGovernmentVideoListData(form: any, page: any) {
        //请求视频列表的数据
        // console.log(form, page, "ChildObjVal");
        if (this.showWarningFlag) {
            form.deviceStatus = 1;
        }
        if (form.groupCode == 0) {
            this.page.pageNo = 1;
        }
        this.showYSVideoFlag = false;
        this.VideoListData = [];
        getBigScreennewInsuranceVideoListV333(form, page, (data: any) => {
            this.VideoListData = data;
            // this.videoList = data;
            this.PageObj = data.page;
        });
    }
    private showYSVideoFlag = false;
    private getBigScreenGovernmentysVideoListData(form: any, page: any) {
        //请求视频列表的数据
        // console.log(form, page, "ChildObjVal");
        if (form.groupCode == 0) {
            form.groupCode = -1;
            this.page.pageNo = 1;
        }
        this.showYSVideoFlag = true;
        this.VideoListData = [];
        getBigScreenGovernmentysVideoList(form, page, (data: any) => {
            this.VideoListData = data;
            // this.videoList = data;
            this.VideoListData.data.forEach((item: any) => {
                item.deviceStatus = 1;
            });
            console.log(this.VideoListData, "ysVideoListData");
            this.PageObj = data.page;
        });
    }
    private getVideoList() {
        return
        //子组件告诉此组件  依据当前组件的数据 获取视频列表的数据  点击更多的emit
        this.PageObj.pageNo = 1; //点击更多 始终使用本页面的数据参数请求  保持 页码为1
        let VideoForm: any = {
            groupCode: this.curentAdcode,
            animalTypeId:
                this.transmitForm.currentType || this.form.currentType,
            farmId: this.form.farmId,
            deviceCode: "",
        };
        this.handlerChildObj();
        if (this.showYSVideoFlag) {
            console.log("ysVideoForm???1418");
            this.getBigScreenGovernmentysVideoListData(VideoForm, this.PageObj);
        } else {
            this.getBigScreenGovernmentVideoListData(VideoForm, this.PageObj);
        }
    }

    private getNextPage() {
        this.handlerChildObj();
        if (
            this.PageObj.pageSize * this.PageObj.pageNo <
            this.PageObj.totalCount
        ) {
            this.PageObj.pageNo++;
            if (this.ChildObjVal.groupCode) {
                this.ChildObjVal.farmId = null;
            }
            if (this.showYSVideoFlag) {
                console.log("ysVideoForm???1435");
                this.getBigScreenGovernmentysVideoListData(
                    this.ChildObjVal,
                    this.PageObj
                );
            } else {
                this.getBigScreenGovernmentVideoListData(
                    this.ChildObjVal,
                    this.PageObj
                );
            }
        }
    }
    private getPrevPage() {
        this.handlerChildObj();
        this.PageObj.pageNo--;
        if (this.PageObj.pageNo >= 1) {
            if (this.ChildObjVal.groupCode) {
                this.ChildObjVal.farmId = null;
            }
            if (this.showYSVideoFlag) {
                console.log("ysVideoForm???1453");
                this.getBigScreenGovernmentysVideoListData(
                    this.ChildObjVal,
                    this.PageObj
                );
            } else {
                this.getBigScreenGovernmentVideoListData(
                    this.ChildObjVal,
                    this.PageObj
                );
            }
        } else {
            this.PageObj.pageNo = 1;
        }
    }
    private ClearObj(val: any) {
        this.ChildObjVal = val;
    }
    private ChildShowVideoListFlag = false; //子组件是否显示视频列表
    private ShowVideoListFlagChange(val: any) {
        this.ChildShowVideoListFlag = val;
    }
    private ChildshowDingVideo = false; //子组件是否显示视频列表
    private showDingVideoChange(val: any) {
        this.ChildshowDingVideo = val;
    }
    private handlerChildObj() {
        this.ChildObjVal.groupCode != undefined &&
        this.ChildObjVal.groupCode != null
            ? (this.ChildObjVal.groupCode = this.ChildObjVal.groupCode)
            : (this.ChildObjVal.groupCode = this.curentAdcode);
        this.ChildObjVal.farmId
            ? (this.ChildObjVal.farmId = this.ChildObjVal.farmId)
            : (this.ChildObjVal.farmId = this.form.farmId);
        this.ChildObjVal.animalTypeId =
            this.transmitForm.currentType || this.form.currentType;
    }
    @Watch("curentAdcode")
    private ChangeCode(newVal: any) {
        this.chooseList();
    }
    @Watch("ChildObjVal", { deep: true })
    private Changechild(val: any) {
        console.log(val);
    }

    private showWarningFlag = false; //是否具有闪烁灯权限

    // api 是否具有闪烁灯权限接口
    private getJudgeExistWarninLightAuth() {
        judgeExistWarninLightAuth("firmScreen", (res: any) => {
            this.showWarningFlag = res.data;
            if (this.showWarningFlag) {
                this.transmitForm.deviceStatus = 1;
                this.getWarningVoice();
                this.getWarningVoiceOnTime();
                this.nextVideoList();
            }
            this.chooseList();
        });
    }
}
